export const INSTRUCTION_NO_TRANSFER = 1;
export const INSTRUCTION_TRANSFER_TO_QUALITYPOOL = 3;

export enum TransferType {
  DIRECT = 'DIRECT',
  QUALITYPOOL = 'QUALITYPOOL',
  NONE = 'NONE'
}

export class QualityPoolInsurers {
  id: number;
  gpid: string;
  onlyCorrespondence: boolean;
}

export interface Insurer {
  id: number;
  name: string;
  hidden: boolean;
  transferType: TransferType;
  correspondenceOnly: boolean;
  email: string;
  emailCC: string;
  brokerNumber: string;
  qualitypoolInsurers: QualityPoolInsurers[]
}

export interface Division {
  id: number;
  label: string;
}

export interface Instruction {
  text: string;
  id: number;
}

export interface ValidationResult {
  reasons: string[];
  instructions: Instruction[];
  error: boolean;
}

export interface Tariff {
  id: number;
  name: string;
  unknown: boolean;
}

export interface ExternalContractData {
  customerId: string;
  id: number;
  crmContractId: number;
  contractNumber: string;
  division: Division;
  insurer: Insurer;
  tariff: Tariff;
  unknownTariffName: string;
  chosenInstruction: Instruction;
  validationResult: ValidationResult;
  originalProvisionRecipientId: number;
}

export interface BrokerContractExistence {
  divisionId: number;
  exists: boolean;
}

export interface Customer {
  // only a few necessary fields
  id: number;
  firstName: string;
  lastName: string;
  birthDay: string;
  email: string;
  personManagementId: string;
}

export interface Consultant {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
}
