import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
    BrokerContractExistence,
    Division,
    ExternalContractData,
    Insurer,
    Tariff, ValidationResult
} from '../shared-objects';
import {environment} from '../../environments/environment';
import {cache, SnackbarService} from '@taures/angular-commons'

const EXTERNAL_CONTRACTS_BASE_URL = '/echse';

@Injectable()
export class ExternalContractsService {

    constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string, private snackbarService: SnackbarService) {
    }

    private insurers: Insurer[];
    private contracts: ExternalContractData[];
    private divisions: Division[];
    private brokerContractExistences: BrokerContractExistence[];
    private brokerMandateBool = false;
    private userIsAuthorized34d = false;
    private userIsAuthorizedToProcessContracts = false;

    public static handleError(error: any, errorMessage: string): Observable<any> {
        let errorCode: string;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorCode = 'client error';
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorCode = `${error.status} ${error.error}`;
        }
        // return an observable with a user-facing error message
        if (error.status === undefined) {
            return throwError(`${errorMessage}`);
        } else {
            return throwError(`${errorMessage} (${errorCode})`);
        }

    }

    private prepareHttpOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json;charset=utf-8',
                Accept: 'application/json'
            })
        };
    }

    public getInsurers(): Insurer[] {
        if (this.insurers) {
            return this.insurers.filter(a => !a.hidden);
        }
    }

    public getContracts(): ExternalContractData[] {
        return this.contracts;
    }

    public getDivisions(): Division[] {
        return this.divisions;
    }

    public hasBrokerMandate(): boolean {
        return this.brokerMandateBool;
    }

    public isUser34dRegistered(): boolean {
        return this.userIsAuthorized34d;
    }

    public isUserAuthorizedToProcessContracts(): boolean {
        return this.userIsAuthorizedToProcessContracts;
    }

    public getBrokerContractExistences(): BrokerContractExistence[] {
        return this.brokerContractExistences;
    }

    public hasBrokerContract(contractTypeId: number): boolean {
        for (const brokerContractExistence of this.brokerContractExistences) {
            if (brokerContractExistence.divisionId === contractTypeId) {
                return brokerContractExistence.exists;
            }
        }
        return false;
    }

    public loadInsurerList(): Observable<Insurer[]> {
        return this.http.get<Insurer[]>(`${EXTERNAL_CONTRACTS_BASE_URL}/insurers`, this.prepareHttpOptions()).pipe(
            map(list => list.sort((a, b) => a.name.localeCompare(b.name, this.locale))),
            tap(list => (this.insurers = list))
        );
    }

    public loadDivisionsList(): Observable<Division[]> {
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/divisions`;
        return this.http.get<Division[]>(url, this.prepareHttpOptions())
          .pipe(
            cache('divisions'),
            tap((list: Division[]) => (this.divisions = list)));
    }

    public loadContractData(personManagementId: string, crmContractId: number): Observable<void> {
        return this.fetchContractData(personManagementId, crmContractId).pipe(
            map(list => {
                this.contracts = list;
            })
        );
    }

    public fetchContractData(personManagementId: string, crmContractId: number): Observable<ExternalContractData[]> {
        let contractIdPathExtension = '';
        if (crmContractId > -1) {
            contractIdPathExtension = `/${crmContractId}`;
        }
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/contracts/${personManagementId}${contractIdPathExtension}`;
        return this.http.get<ExternalContractData[]>(url, this.prepareHttpOptions());
    }

    public loadBrokerContractExistence(personManagementId: string): Observable<void> {
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/brokercontracts/${personManagementId}`;
        return this.http.get<BrokerContractExistence[]>(url, this.prepareHttpOptions()).pipe(
            map(list => {
                this.brokerContractExistences = list;
            })
        );
    }

    public loadBrokerMandate(personManagementId: string): Observable<void> {

        return this.http.get(`${EXTERNAL_CONTRACTS_BASE_URL}/brokermandates/${personManagementId}/exists`, {
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        }).pipe(
            map(response => {
                if (response.status === 200) {
                    this.brokerMandateBool = true;
                }
            })
        );
    }

    public loadUserIsAuthorized34d(): Observable<void> {
        return this.http.get(`${EXTERNAL_CONTRACTS_BASE_URL}/users/34d-registered`, {
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        }).pipe(
            map(response => {
                if (response.status === 200) {
                    this.userIsAuthorized34d = true;
                } else {
                    this.snackbarService.queueToastMessage({ title: 'Hinweis', message: 'Ausführen des Vermittlerwechsels nur mit Registrierung nach § 34d möglich!', notificationType: 'notification', duration: -1 })
                }
            })
        );
    }

    public loadUserIsAuthorizedToProcessContracts(): Observable<void> {
        return this.http.get(`${EXTERNAL_CONTRACTS_BASE_URL}/users/permission/process-contracts`, {
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        }).pipe(
            map(response => {
                if (response.status === 200) {
                    this.userIsAuthorizedToProcessContracts = true;
                }
            })
        );
    }

    public fetchValidationResult(externalContractData: ExternalContractData): Observable<ValidationResult> {
        return this.http.post<ValidationResult>(`${EXTERNAL_CONTRACTS_BASE_URL}/contracts/instructions`,
            JSON.stringify(externalContractData), this.prepareHttpOptions());
    }

    public fetchTariffsForContract(contractId: number): Observable<Tariff[]> {
        return this.http.get<Tariff[]>(`${EXTERNAL_CONTRACTS_BASE_URL}/tariffs/contract/${contractId}`, this.prepareHttpOptions());
    }

    public fetchTariffs(insurerId: number, divisionId: number): Observable<Tariff[]> {
        return this.http.get<Tariff[]>(`${EXTERNAL_CONTRACTS_BASE_URL}/tariffs/${insurerId}/${divisionId}`, this.prepareHttpOptions());
    }

    public getKonzeptUrl(personManagementId: string): string {
        return `${environment.konzeptUrl}/customers/${personManagementId}`;
    }

    public getDocsUrl(customerId: number): string {
        return `${environment.docsUrl}/customers/${customerId}`;
    }

    public saveContracts(externalContractData: ExternalContractData[]): Observable<string> {
        if (externalContractData.length > 0) {
            const url = `${EXTERNAL_CONTRACTS_BASE_URL}/contracts`;
            return this.http.put<string>(url, JSON.stringify(externalContractData), this.prepareHttpOptions());
        }
    }

    public changeProvisionRecipient(personManagementId: string, consultantId: string): Observable<string> {
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/contracts/${personManagementId}/provision/${consultantId}`;
        return this.http.put<string>(url, '', this.prepareHttpOptions());
    }

    public verifyContracts(personManagementId: string): Observable<Object> {
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/customers/${personManagementId}/verify`;
        return this.http.get(url, this.prepareHttpOptions());
    }

    public processContracts(personManagementId: string): Observable<Object> {
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/contracts/${personManagementId}/process`;
        return this.http.get(url, this.prepareHttpOptions());
    }

    public saveInitiator(contracts: ExternalContractData[]): Observable<any> {
        let contractIds = '';
        contracts.forEach(c => {
            if (contractIds.length === 0) {
                contractIds = String(c.id);
            } else {
                contractIds = contractIds.concat(',', String(c.id));
            }
        });
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/contracts/${contractIds}/initiator`;
        return this.http.put(url, {}, this.prepareHttpOptions());
    }

    saveInsurer (value: Insurer): Observable<Insurer> {
        const url = `${EXTERNAL_CONTRACTS_BASE_URL}/insurers/${value.id}`;
        return this.http.put<Insurer>(url, value, this.prepareHttpOptions());
    }
}
